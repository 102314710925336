import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";
import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Empty,
  FooterContainer,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
} from "./styles";

interface SocialLinkProps {
  href: string;
  src: string;
}

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const SocialLink = ({ href, src }: SocialLinkProps) => {
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        key={src}
        aria-label={src}
        style={{margin:10}}
      >
        <SvgIcon src={src}  width="25px" height="25px" />
      </a>
    );
  };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={10} md={10} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Large to="/">{t("Tell us everything")}</Large>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <a href="mailto:info@streamz.tech">
                <Chat>{t(`Let's Chat`)}</Chat>
              </a>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Title>{t("Policy")}</Title>
                <a href="https://www.privacypolicies.com/live/efc94e20-14a8-4c7e-afb1-1248e1efa212">
                <Chat>Privacy Policy</Chat>
                </a>
                <a href={require('./TOU.pdf')}>
                <Chat>Terms Of Service</Chat>
                </a>
            </Col>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <Para>Streamz Holding Enterprise Solutions HQ</Para>
              <Para>131 Nightingale Street</Para>
              <Para>Meredale, ext 4</Para>
              <Para>Johannesburg</Para>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >

           
            <NavLink to="/">
              <LogoContainer>
              <img alt='logo' style={{ width: 100, marginTop:10}} src="/img/icons/logo192.png" />

              </LogoContainer>
            </NavLink>

           
            
            <FooterContainer>
             
              <SocialLink
                href="https://twitter.com/streamzpay"
                src="twitter.svg"
              />
              <SocialLink
                href="https://www.instagram.com/streamzpay/"
                src="instagram.svg"
              />
              
            </FooterContainer>
          </Row>
        </Container>


        <Row justify="center" align="middle" style={{marginLeft:"15%", marginRight:"15%", marginTop:10}}>
              <div style={{color: '#18216d', fontSize:9, textAlign:"center"}}>Streamz Pay is a Juristic Representative of Mavuyisi Group, A Financial services provider, FSP 45579</div>
        </Row>
      </Extra>

     
      
    </>
  );
};

export default withTranslation()(Footer);
