const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/app", "/application"],
    exact: false,
    component: "Application",
  },
  {
    path: ["/leaderboard"],
    exact: true,
    component: "Confirm",
  },
  {
    path: ["/pay"],
    exact: false,
    component: "Pay",
  }
];

export default routes;
